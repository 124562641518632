import IMapElement from '../model/IMapElement'
import { IServicesDefinition } from '../model/IService'
import { request } from './RequestUtils'

const apiURL = process.env.REACT_APP_API_BASE_URL

export default class MapElementService {
  public async fetchMapElementData(): Promise<IMapElement[]> {
    const response = await fetch(`${apiURL}/mapElements`)
    const mapElements = await response.json()
    return mapElements
  }

  public async fetchMapElementDataByCampaign(campaignName: string): Promise<IMapElement[]> {
    return request({
      url: `${apiURL}/mapElements?campaign=${campaignName}`,
    })
  }

  public async fetchMapElementsLabels(): Promise<string[]> {
    return request({
      url: `${apiURL}/mapElements/names`,
    })
  }

  public async addMapElement(mapElement: IMapElement) {
    return request({
      method: 'POST',
      body: JSON.stringify(mapElement),
      url: `${apiURL}/mapElements`,
    })
  }

  public async sendMapElementChat(
    mapElement: IMapElement,
    text: string,
    from: string,
    to: string,
    servicesAvailable: IServicesDefinition[]
  ) {
    return request({
      method: 'POST',
      body: JSON.stringify({ mapElement, text, from, to, servicesAvailable }),
      url: `${apiURL}/mapElements/chat`,
    })
  }

  public async sendMapElementDynamicUpdate(
    mapElement: IMapElement,
    msgType: string,
    servicesAvailable: IServicesDefinition[]
  ) {
    return request({
      method: 'POST',
      body: JSON.stringify({ mapElement, msgType, servicesAvailable }),
      url: `${apiURL}/mapElements/sendDynamicUpdate`,
    })
  }

  public async sendMapElementTask(
    mapElement: IMapElement,
    msgType: string,
    taskType: string,
    vehicle: string,
    servicesAvailable: IServicesDefinition[]
  ) {
    return request({
      method: 'POST',
      body: JSON.stringify({ mapElement, msgType, taskType, vehicle, servicesAvailable }),
      url: `${apiURL}/mapElements/sendTask`,
    })
  }
}
