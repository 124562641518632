import Parser from 'html-react-parser'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Card from 'reactstrap/lib/Card'
import CardBody from 'reactstrap/lib/CardBody'
import CardTitle from 'reactstrap/lib/CardTitle'
import IAircraft from '../../../model/IAircraft'
import IAisShip from '../../../model/IAisShip'
import IAsset from '../../../model/IAsset'
import IAuthState, { hasL2Permissions, isL4 } from '../../../model/IAuthState'
import IContact from '../../../model/IContact'
import IRipplesState from '../../../model/IRipplesState'
import { setSidePanelVisibility, toggleVehicleModal } from '../../../redux/ripples.actions'
import AircraftService from '../../../services/AircraftUtils'
import AISService from '../../../services/AISUtils'
import ContactService from '../../../services/ContactUtils'
import DateService from '../../../services/DateUtils'
import oracleLogo from '../../../assets/oracle.jpeg'
import { IServicesDefinition } from '../../../model/IService'
import { isCatlBrokerServiceAvailable } from '../../../services/ServicesUtils'
import IMapElement from '../../../model/IMapElement'
import MapElementService from '../../../services/MapElementUtils'
const { NotificationManager } = require('react-notifications')

interface PropsType {
  auth: IAuthState
  title: string
  content: Map<string, string>
  visibility: boolean
  authenticated: boolean
  selectedVehicle?: IAsset
  selectedContact: IContact | null
  vehicleSelected: string // TopNav vehicle
  selectedMapElement: IMapElement | null
  aircrafts: IAircraft[]
  aisShips: IAisShip[]
  servicesAvailable: IServicesDefinition[]
  onSettingsClick: () => void
  setSidePanelVisibility: (v: boolean) => void
  aircraftToAsset: (a: IAircraft) => void
  aisShipToAsset: (ship: IAisShip) => void
}

interface StateType {
  aircraftSelected: string
  isAircraftToAssetModalOpen: boolean
  aircraftInput: string
  aisSelected: string
  isAisToAssetModalOpen: boolean
  aisShipInput: { name: string; type: string }
  isOracleModalOpen: boolean
  isUploadImageToOracleOpen: boolean
  isImageClassificationOpen: boolean
  contactImagesURL: string[]
  contactImagesClassification: string[]
  contactImageSelected?: File
  chatMessageInput: string
  chatMessageDestinationInput: string
  isCatlMessageOpen: boolean
  isCatlTaskTypeOpen: boolean
  catlTaskType: string
  catlMsgType: string
}

class SidePanel extends Component<PropsType, StateType> {
  private contactService: ContactService = new ContactService()
  private mapElementService: MapElementService = new MapElementService()
  private aircraftService: AircraftService = new AircraftService()
  private aisService: AISService = new AISService()

  constructor(props: PropsType) {
    super(props)
    this.state = {
      aircraftSelected: '',
      isAircraftToAssetModalOpen: false,
      aircraftInput: '',
      aisSelected: '',
      isAisToAssetModalOpen: false,
      aisShipInput: { name: '', type: '' },
      isOracleModalOpen: false,
      isUploadImageToOracleOpen: false,
      isImageClassificationOpen: false,
      contactImagesURL: [],
      contactImagesClassification: [],
      chatMessageInput: '',
      chatMessageDestinationInput: '',
      isCatlMessageOpen: false,
      isCatlTaskTypeOpen: false,
      catlTaskType: '',
      catlMsgType: '',
    }

    this.sendContact = this.sendContact.bind(this)
    this.sendMapElementTask = this.sendMapElementTask.bind(this)
    this.sendMapElementDynamicUpdate = this.sendMapElementDynamicUpdate.bind(this)
    this.sendContactChat = this.sendContactChat.bind(this)
    this.sendMapElementChat = this.sendMapElementChat.bind(this)
    this.handleImageSelected = this.handleImageSelected.bind(this)
    this.handleUploadImage = this.handleUploadImage.bind(this)
    this.updateLocalStorage = this.updateLocalStorage.bind(this)
    this.toggleAircraftToAssetModal = this.toggleAircraftToAssetModal.bind(this)
    this.toggleAisToAssetModal = this.toggleAisToAssetModal.bind(this)
    this.addAircraftAsset = this.addAircraftAsset.bind(this)
    this.addAisAsset = this.addAisAsset.bind(this)
  }

  public buildContent(content: any) {
    const items: JSX.Element[] = []
    for (const key in content) {
      items.push(
        <li key={key}>
          <b>{key}</b>: {Parser(content[key])}
        </li>
      )
    }
    return <ul>{items}</ul>
  }

  public render() {
    if (this.props.visibility) {
      const content = this.buildContent(this.props.content)
      let displayAircraftBtn: boolean = false
      let displayAisBtn: boolean = false

      // display aircraft option btn
      content.props.children.forEach((elem: any) => {
        if (elem.key === 'flight') displayAircraftBtn = true
        if (elem.key === 'mmsi') displayAisBtn = true
      })

      return (
        <>
          <Card className="side-panel">
            <CardBody className="scrollable">
              <CardTitle
                className={`${this.props.selectedContact ? 'contacts_opt' : ''} ${
                  this.props.selectedMapElement ? 'mapElements_opt' : ''
                }`}
              >
                <h4 className="mr-auto">{this.props.title}</h4>
                {this.props.authenticated &&
                  this.props.selectedVehicle &&
                  this.props.selectedContact === null &&
                  this.props.selectedMapElement === null &&
                  !displayAircraftBtn &&
                  !displayAisBtn && <i className="fas fa-cog fa-lg" onClick={this.props.onSettingsClick} />}
                {this.props.authenticated &&
                  !isL4(this.props.auth) &&
                  this.props.selectedContact === null &&
                  this.props.selectedMapElement === null &&
                  !displayAircraftBtn &&
                  !displayAisBtn &&
                  (this.props.title.includes('ccu') ||
                    this.props.title.includes('spot') ||
                    this.props.selectedVehicle) && (
                    <Link className="asset-link" to="/asset/profile">
                      <i title="Asset info" className="fas fa-info-circle fa-lg" />
                    </Link>
                  )}
                {this.props.authenticated &&
                  !isL4(this.props.auth) &&
                  this.props.selectedContact &&
                  this.props.selectedMapElement === null &&
                  isCatlBrokerServiceAvailable(this.props.servicesAvailable) && (
                    <>
                      <Button
                        id="updateBtn"
                        className="btn btn-sm m-1"
                        color="primary"
                        title="Send TaskAdmin"
                        onClick={() => this.sendContact('UPDATE')}
                      >
                        UPDATE
                      </Button>
                      <Button
                        id="pushBtn"
                        className="btn btn-sm m-1"
                        color="primary"
                        title="Send TaskAdmin"
                        onClick={() => this.sendContact('PUSH')}
                      >
                        PUSH
                      </Button>
                      {!this.state.isCatlMessageOpen ? (
                        <Button
                          id="chatBtn"
                          className="btn btn-sm m-1"
                          title="Send ChatMessage"
                          onClick={() => this.toggleCatlMessageModal()}
                        >
                          CHAT
                        </Button>
                      ) : (
                        <></>
                      )}
                      {/*
                    <Button
                      id="syncImage"
                      className="btn btn-sm m-1"
                      color="primary"
                      title="Send Image to Oracle"
                      onClick={() => this.toggleOracleModal()}
                    >
                      Image classification
                    </Button>
                    */}
                    </>
                  )}

                {this.props.authenticated &&
                  !isL4(this.props.auth) &&
                  this.props.selectedMapElement &&
                  this.props.selectedContact === null &&
                  isCatlBrokerServiceAvailable(this.props.servicesAvailable) && (
                    <>
                      <Button
                        id="dynamicUpdateMapElementBtn"
                        className="btn btn-sm m-1"
                        color="primary"
                        title="Send DynamicUpdate"
                        onClick={() => this.sendMapElementDynamicUpdate()}
                      >
                        Dynamic Update
                      </Button>
                      {!this.state.isCatlTaskTypeOpen ? (
                        <>
                          <Button
                            id="pushMapElementBtn"
                            className="btn btn-sm m-1"
                            color="primary"
                            title="Send TaskAdmin"
                            onClick={() => this.toggleCatlTaskTypeModal('TASKADMIN_PUSH_MAP_ELEMENT', '')}
                          >
                            Task Admin
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                      {!this.state.isCatlMessageOpen ? (
                        <Button
                          id="chatBtn"
                          className="btn btn-sm m-1"
                          title="Send ChatMessage"
                          onClick={() => this.toggleCatlMessageModal()}
                        >
                          CHAT
                        </Button>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                {this.props.authenticated &&
                  localStorage.getItem('user-lat') !== null &&
                  localStorage.getItem('user-lng') !== null &&
                  this.props.title.includes('Location') && (
                    <i
                      id="deleteMyLocationBtn"
                      className="fas fa-trash"
                      color="primary"
                      title="Remove location"
                      onClick={() => this.updateLocalStorage()}
                    />
                  )}
                {this.props.authenticated && !isL4(this.props.auth) && displayAircraftBtn && (
                  <i
                    id="aircraftToAsset"
                    className="fas fa-plus-square fa-lg"
                    color="primary"
                    title="Set aircraft as Asset"
                    onClick={() => this.toggleAircraftToAssetModal(this.props.title)}
                  />
                )}
                {this.props.authenticated && hasL2Permissions(this.props.auth) && displayAisBtn && (
                  <i
                    id="aisToAsset"
                    className="fas fa-plus-square fa-lg"
                    color="primary"
                    title="Set AIS as Asset"
                    onClick={() => this.toggleAisToAssetModal(content)}
                  />
                )}
              </CardTitle>
              <div>{content}</div>
            </CardBody>

            {this.state.isCatlMessageOpen ? this.buildCatlMessageField() : <></>}
            {this.state.isCatlTaskTypeOpen ? this.buildCatlTaskTypeField() : <></>}
          </Card>

          {/*this.buildOracleModal()*/}
          {this.buildAircraftToAssetModal()}
          {this.buildAisToAssetModal()}
        </>
      )
    }
    return <></>
  }

  // send over TCP
  public async sendContact(type: string) {
    if (this.props.selectedContact !== null) {
      if (this.props.vehicleSelected) {
        const response = await this.contactService.sendContact(
          this.props.selectedContact,
          type,
          this.props.vehicleSelected
        )
        if (response.status === 'success') {
          NotificationManager.success(response.message)
        } else {
          NotificationManager.warning(response.message)
        }
      } else {
        NotificationManager.warning('Please select target vehicle')
      }
    }
  }

  private async sendMapElementDynamicUpdate() {
    if (this.props.selectedMapElement !== null) {
      const response = await this.mapElementService.sendMapElementDynamicUpdate(
        this.props.selectedMapElement,
        'DYNAMICUPDATE_MAP_ELEMENT',
        this.props.servicesAvailable
      )
      if (response.status === 'success') {
        NotificationManager.success(response.message)
      } else {
        NotificationManager.warning(response.message)
      }
    }
  }

  private async sendMapElementTask() {
    if (this.props.selectedMapElement !== null) {
      if (this.props.vehicleSelected) {
        const response = await this.mapElementService.sendMapElementTask(
          this.props.selectedMapElement,
          this.state.catlMsgType,
          this.state.catlTaskType,
          this.props.vehicleSelected,
          this.props.servicesAvailable
        )
        if (response.status === 'success') {
          NotificationManager.success(response.message)
          this.toggleCatlTaskTypeModal('', '')
        } else {
          NotificationManager.warning(response.message)
        }
      } else {
        NotificationManager.warning('Please select target vehicle')
      }
    }
  }

  private async sendContactChat() {
    if (this.props.selectedContact !== null) {
      const response = await this.contactService.sendContactChat(
        this.props.selectedContact,
        this.state.chatMessageInput,
        this.props.auth.currentUser.email,
        this.state.chatMessageDestinationInput
      )
      if (response.status === 'success') {
        NotificationManager.success(response.message)
        this.toggleCatlMessageModal()
      } else {
        NotificationManager.warning(response.message)
      }
    } else {
      NotificationManager.warning('Contact not found')
    }
  }

  private async sendMapElementChat() {
    if (this.props.selectedMapElement !== null) {
      const response = await this.mapElementService.sendMapElementChat(
        this.props.selectedMapElement,
        this.state.chatMessageInput,
        this.props.auth.currentUser.email,
        this.state.chatMessageDestinationInput,
        this.props.servicesAvailable
      )
      if (response.status === 'success') {
        NotificationManager.success(response.message)
        this.toggleCatlMessageModal()
      } else {
        NotificationManager.warning(response.message)
      }
    } else {
      NotificationManager.warning('Area not found')
    }
  }

  private toggleOracleModal() {
    this.setState({
      isOracleModalOpen: !this.state.isOracleModalOpen,
      isUploadImageToOracleOpen: false,
      isImageClassificationOpen: false,
      contactImageSelected: undefined,
      contactImagesURL: [],
      contactImagesClassification: [],
    })
  }

  private toggleUploadImageToOracle() {
    this.setState({
      isUploadImageToOracleOpen: !this.state.isUploadImageToOracleOpen,
      isImageClassificationOpen: false,
      contactImageSelected: undefined,
    })
  }

  private async fetchContactImages() {
    const contactImages: string[] = await this.contactService.listContactImages(this.props.title)
    if (contactImages.length > 0) {
      const apiURL = process.env.REACT_APP_API_BASE_URL + '/contacts/image/'

      const contactImagesURLAux: string[] = this.state.contactImagesURL
      const contactImagesClassificationAux: string[] = this.state.contactImagesClassification
      contactImages.forEach(async (imgName) => {
        contactImagesURLAux.push(apiURL + imgName)
        contactImagesClassificationAux.push('undefined')
      })

      this.setState({
        contactImagesURL: contactImagesURLAux,
        contactImagesClassification: contactImagesClassificationAux,
        isUploadImageToOracleOpen: false,
        isImageClassificationOpen: !this.state.isImageClassificationOpen,
        contactImageSelected: undefined,
      })
    } else {
      NotificationManager.warning('No images available')
    }
  }

  private async fetchContactClassification(img: string) {
    NotificationManager.info('Fetch image classification...')

    const imgName = img
      .substring(img.lastIndexOf('/') + 1)
      .split('.')
      .slice(0, -1)
      .join('.')
    let indexToUpdate = -1
    this.state.contactImagesURL.forEach((imgURL, index) => {
      if (imgURL === img) {
        indexToUpdate = index
      }
    })

    const contactImagesClassificationAux: string[] = this.state.contactImagesClassification
    if (indexToUpdate !== -1) {
      const resp = await this.contactService.fetchContactClassification(imgName)
      if (resp.status === 'error') {
        NotificationManager.warning(resp.message)
      } else if (resp.status === 'success') {
        NotificationManager.success('Classfication founded')
        contactImagesClassificationAux[indexToUpdate] = resp.message
      } else {
        NotificationManager.warning(resp.message)
        contactImagesClassificationAux[indexToUpdate] = resp.message
      }
    } else {
      NotificationManager.warning('Image not found...')
    }

    this.setState({ contactImagesClassification: contactImagesClassificationAux })
  }

  private printContactImageClassification(msg: string) {
    let classificationVisible: boolean = false
    try {
      const myobj = JSON.parse(msg)
      classificationVisible = true
      return (
        <>
          <p>
            <b>Name: </b> {myobj.name}
          </p>
          <p>
            <b>Confidence: </b> {myobj.confidence}
          </p>
          <p>
            <b>GenDate: </b> {DateService.timestampMsToReadableDate(Number(myobj.genDate))}
          </p>
        </>
      )
    } catch (error) {
      classificationVisible = false
    }

    if (!classificationVisible) {
      return <>{msg}</>
    }
  }

  private buildOracleModal() {
    return (
      <Modal isOpen={this.state.isOracleModalOpen} toggle={() => this.toggleOracleModal()}>
        <ModalHeader toggle={() => this.toggleOracleModal()}>
          <img id="oracleLogo" src={oracleLogo} alt="oracle" />
          Image Classification - OCI
        </ModalHeader>

        {!this.state.isUploadImageToOracleOpen && !this.state.isImageClassificationOpen ? (
          <ModalBody>
            <Button color="primary" className="oracleModalBtn" onClick={() => this.toggleUploadImageToOracle()}>
              Upload image
            </Button>
            <br />
            <Button color="primary" className="oracleModalBtn" onClick={() => this.fetchContactImages()}>
              View image classification
            </Button>
          </ModalBody>
        ) : (
          <></>
        )}

        {this.state.isUploadImageToOracleOpen ? (
          <ModalBody>
            <input
              type="file"
              id="imgInput"
              name="image"
              accept="image/png, image/jpeg"
              onChange={(event) => {
                this.handleImageSelected(event)
              }}
            />
            <img
              id="imgSelected"
              src="https://icons.veryicon.com/png/o/miscellaneous/classic-common-line-icon/danger-13.png"
              alt="User file"
            />

            <ModalFooter>
              <Button color="warning" onClick={() => this.toggleUploadImageToOracle()}>
                cancel
              </Button>
              {this.state.contactImageSelected ? (
                <Button color="success" onClick={() => this.handleUploadImage()}>
                  Upload
                </Button>
              ) : (
                <></>
              )}
            </ModalFooter>
          </ModalBody>
        ) : (
          <></>
        )}

        {this.state.isImageClassificationOpen ? (
          <ModalBody>
            <div id="contactsClassification">
              {this.state.contactImagesURL.map((imgURL, index) => {
                return (
                  <div key={index} className="contactsClassification_row">
                    {index !== 0 ? <hr /> : <></>}
                    <div className="contactsClassification_image">
                      <img id="contactImage_index" src={imgURL} alt="contact_img" />
                    </div>
                    <div className="contactsClassification_descripion">
                      {this.state.contactImagesClassification[index] === 'undefined' ? (
                        <Button color="info" onClick={() => this.fetchContactClassification(imgURL)}>
                          Fetch classification
                        </Button>
                      ) : (
                        this.printContactImageClassification(this.state.contactImagesClassification[index])
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </ModalBody>
        ) : (
          <></>
        )}
      </Modal>
    )
  }

  private handleImageSelected(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const file = event.target.files[0]
      const img = document.getElementById('imgSelected')
      if (img) {
        img.setAttribute('src', URL.createObjectURL(file))
        this.setState({ contactImageSelected: file })
      }
    }
  }

  private async handleUploadImage() {
    const apiURL = process.env.REACT_APP_API_BASE_URL
    if (this.state.contactImageSelected) {
      if (this.props.selectedContact !== null && apiURL !== undefined) {
        const formData = new FormData()
        formData.append('image', this.state.contactImageSelected)
        formData.append('contactID', this.props.selectedContact.label)

        fetch(apiURL + '/contacts/image', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            type: 'formData',
          },
          body: formData,
        }).then(
          (res) => {
            if (res.ok) {
              NotificationManager.success('Updated contact image')
              this.toggleOracleModal()
            } else if (res.status === 401) {
              NotificationManager.error('Cannot upload image...')
            }
          },
          (e) => {
            NotificationManager.error('Cannot upload image')
          }
        )
      }
    } else {
      NotificationManager.error('Please select image')
    }
  }

  // update local storage
  public updateLocalStorage() {
    localStorage.removeItem('user-lat')
    localStorage.removeItem('user-lng')
    this.props.setSidePanelVisibility(false)
    NotificationManager.success('Removed user location')
  }

  // aircraft to asset
  private buildAircraftToAssetModal() {
    return (
      <Modal isOpen={this.state.isAircraftToAssetModalOpen} toggle={() => this.toggleAircraftToAssetModal('')}>
        <ModalHeader toggle={() => this.toggleAircraftToAssetModal('')}>
          Add asset with aircraft hex [{this.state.aircraftSelected}]
        </ModalHeader>
        <ModalBody>
          <p>The aircraft will be considered an asset. Please insert asset name.</p>
          <input
            className="aircraft-input"
            placeholder="Asset name"
            value={this.state.aircraftInput}
            onChange={(event: any) => this.setState({ aircraftInput: event.target.value })}
          />
        </ModalBody>
        {this.state.aircraftInput.length > 0 && (
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.addAircraftAsset(this.state.aircraftSelected, this.state.aircraftInput)}
            >
              Add
            </Button>
          </ModalFooter>
        )}
      </Modal>
    )
  }

  private toggleAircraftToAssetModal(aircraftId: string) {
    this.setState({
      aircraftSelected: aircraftId,
      aircraftInput: '',
      isAircraftToAssetModalOpen: !this.state.isAircraftToAssetModalOpen,
    })
  }

  // AIS to asset
  private buildAisToAssetModal() {
    return (
      <Modal isOpen={this.state.isAisToAssetModalOpen} toggle={() => this.toggleAisToAssetModal(undefined)}>
        <ModalHeader toggle={() => this.toggleAisToAssetModal(undefined)}>
          Add asset: {this.state.aisSelected}
        </ModalHeader>
        <ModalBody>
          <p>The ship will be considered an asset. Please insert the name of the asset?</p>
          <input
            className="aisShip-input"
            placeholder="Asset name"
            value={this.state.aisShipInput.name}
            onChange={(event: any) =>
              this.setState({ aisShipInput: { name: event.target.value, type: this.state.aisShipInput.type } })
            }
          />
          <select
            id={this.state.aisShipInput.type === '' ? 'aisShipType-type-hidden' : 'aisShipType-type'}
            className="aisShip-input"
            value={this.state.aisShipInput.type}
            onChange={(event: any) =>
              this.setState({ aisShipInput: { name: this.state.aisShipInput.name, type: event.target.value } })
            }
            onClick={(event) => event.stopPropagation()}
          >
            <option disabled={true} hidden={true} value="">
              Asset type
            </option>
            <option value="AUV" onClick={(event) => event.stopPropagation()}>
              AUV
            </option>
            <option value="ASV" onClick={(event) => event.stopPropagation()}>
              ASV
            </option>
            <option value="CCU" onClick={(event) => event.stopPropagation()}>
              CCU
            </option>
            <option value="GLIDER" onClick={(event) => event.stopPropagation()}>
              GLIDER
            </option>
            <option value="MOBILE_SENSOR" onClick={(event) => event.stopPropagation()}>
              MOBILE_SENSOR
            </option>
            <option value="ROV" onClick={(event) => event.stopPropagation()}>
              ROV
            </option>
            <option value="STATIC_SENSOR" onClick={(event) => event.stopPropagation()}>
              STATIC_SENSOR
            </option>
            <option value="UAV" onClick={(event) => event.stopPropagation()}>
              UAV
            </option>
            <option value="WAVY_DRIFTER" onClick={(event) => event.stopPropagation()}>
              WAVY_DRIFTER
            </option>
            <option value="undefined" onClick={(event) => event.stopPropagation()}>
              undefined
            </option>
          </select>
        </ModalBody>
        {this.state.aisShipInput.name.length > 0 && this.state.aisShipInput.type.length > 0 && (
          <ModalFooter>
            <Button color="primary" onClick={() => this.addAisAsset()}>
              Add
            </Button>
          </ModalFooter>
        )}
      </Modal>
    )
  }

  private toggleAisToAssetModal(content: JSX.Element | undefined) {
    if (content !== undefined) {
      this.setState({
        aisSelected: content.props.children[9].props.children[2].props.children,
        isAisToAssetModalOpen: !this.state.isAisToAssetModalOpen,
        aisShipInput: { name: '', type: '' },
      })
    } else {
      this.setState({
        aisSelected: '',
        isAisToAssetModalOpen: false,
        aisShipInput: { name: '', type: '' },
      })
    }
  }

  private addAircraftAsset(aircraftID: string, aircraftName: string) {
    this.props.aircrafts.forEach(async (aircraft: IAircraft) => {
      if (aircraft.hex === aircraftID) {
        try {
          const resp = await this.aircraftService.addAssetFromAircraft(aircraft, aircraftName)
          if (resp.status === 'Success') {
            NotificationManager.success(resp.message)
            this.props.aircraftToAsset(aircraft)
            this.props.setSidePanelVisibility(false)
          } else {
            NotificationManager.warning(resp.message)
          }
        } catch (error) {
          NotificationManager.warning('error')
        }
      }
    })
    this.toggleAircraftToAssetModal('')
  }

  private addAisAsset() {
    if (/\s/.test(this.state.aisShipInput.name)) {
      NotificationManager.warning('Name cannot have white spaces...')
    } else {
      this.props.aisShips.forEach(async (aisShip: IAisShip) => {
        if (this.state.aisSelected.includes(aisShip.mmsi)) {
          const resp = await this.aisService.addAssetFromAis(aisShip, this.state.aisShipInput)
          if (resp.status === 'Success') {
            NotificationManager.success(resp.message)
            this.props.aisShipToAsset(aisShip)
            this.props.setSidePanelVisibility(false)
            this.setState({
              aisSelected: '',
              isAisToAssetModalOpen: false,
              aisShipInput: { name: '', type: '' },
            })
          } else {
            NotificationManager.warning(resp.message)
          }
        }
      })
    }
  }

  // text to catl_message
  private buildCatlMessageField() {
    return (
      <>
        <hr />
        <div id="catlChat-container">
          <div id="catlChat-container-inputs">
            <textarea
              className="catl-chat-input"
              id={'catlChat-input'}
              placeholder="Chat text..."
              value={this.state.chatMessageInput}
              onChange={(event: any) => this.setState({ chatMessageInput: event.target.value })}
            />
            <input
              className="catl-chat-input"
              id={'catlChat-destination-input'}
              placeholder="Destination (optional)"
              value={this.state.chatMessageDestinationInput}
              onChange={(event: any) => this.setState({ chatMessageDestinationInput: event.target.value })}
            />
          </div>
          <div>
            <Button
              id="sendChatBtn"
              className="btn btn-sm m-1"
              color="primary"
              title="Send chat message"
              disabled={this.state.chatMessageInput.length > 0 ? false : true}
              onClick={() => {
                if (this.props.selectedContact) {
                  this.sendContactChat()
                }
                if (this.props.selectedMapElement) {
                  this.sendMapElementChat()
                }
              }}
            >
              send
            </Button>
            <Button
              id="cancelChatBtn"
              className="btn btn-sm m-1"
              color="danger"
              title="Cancel chat message"
              onClick={() => this.toggleCatlMessageModal()}
            >
              Cancel
            </Button>
          </div>
        </div>
      </>
    )
  }

  // task type to catl
  private buildCatlTaskTypeField() {
    return (
      <>
        <hr />
        <div id="catlChat-container">
          <div id="catlChat-container-inputs">
            <select
              className="mapSystem-input"
              value={this.state.catlTaskType}
              onChange={(event: any) => {
                this.setState({ catlTaskType: event.target.value })
              }}
            >
              <option disabled={true} hidden={true} value="">
                Task Type
              </option>
              <option value="PREPARATION" onClick={(event) => event.stopPropagation()}>
                PREPARATION
              </option>
              <option value="STANDBY" onClick={(event) => event.stopPropagation()}>
                STANDBY
              </option>
              <option value="SURVEY" onClick={(event) => event.stopPropagation()}>
                SURVEY
              </option>
            </select>
          </div>
          <div>
            <Button
              id="sendTaskBtn"
              className="btn btn-sm m-1"
              color="primary"
              title="Send TaskAdmin"
              onClick={() => {
                if (this.props.selectedMapElement) {
                  this.sendMapElementTask()
                }
              }}
            >
              send
            </Button>
            <Button
              id="cancelTaskBtn"
              className="btn btn-sm m-1"
              color="danger"
              title="Cancel TaskAdmin"
              onClick={() => this.toggleCatlTaskTypeModal('', '')}
            >
              Cancel
            </Button>
          </div>
        </div>
      </>
    )
  }

  private toggleCatlMessageModal() {
    if (!this.state.isCatlMessageOpen) {
      NotificationManager.info('Insert text message')
    }
    this.setState({
      isCatlMessageOpen: !this.state.isCatlMessageOpen,
      isCatlTaskTypeOpen: false,
      chatMessageInput: '',
      chatMessageDestinationInput: '',
    })
  }

  private toggleCatlTaskTypeModal(msgType: string, taskType: string) {
    if (!this.state.isCatlTaskTypeOpen) {
      NotificationManager.info('Select task type')
    }
    this.setState({
      isCatlTaskTypeOpen: !this.state.isCatlTaskTypeOpen,
      isCatlMessageOpen: false,
      catlTaskType: taskType,
      catlMsgType: msgType,
    })
  }
}

function mapStateToProps(state: IRipplesState) {
  const { sidePanelTitle, sidePanelContent, isSidePanelVisible, auth, editVehicle } = state
  return {
    content: sidePanelContent,
    title: sidePanelTitle,
    visibility: isSidePanelVisible,
    authenticated: auth.authenticated,
    selectedVehicle: editVehicle,
    auth: state.auth,
    selectedContact: state.contactSelected,
    vehicleSelected: state.vehicleSelected,
    selectedMapElement: state.mapElementSelected,
    aircrafts: state.assets.aircrafts,
    aisShips: state.assets.aisShips,
    servicesAvailable: state.servicesAvailable,
  }
}

const actionCreators = {
  toggleVehicleModal,
  setSidePanelVisibility,
}

export default connect(mapStateToProps, actionCreators)(SidePanel)
