import L from 'leaflet'
import milsymbol from 'milsymbol'
import React, { Component } from 'react'
import { Marker } from 'react-leaflet'
import { connect } from 'react-redux'
import IAsset from '../../../model/IAsset'
import IAssetAwareness from '../../../model/IAssetAwareness'
import IRipplesState from '../../../model/IRipplesState'
import {
  setEditVehicle,
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  sidePanelVerification,
} from '../../../redux/ripples.actions'
import DateService from '../../../services/DateUtils'
import PositionService from '../../../services/PositionUtils'
import AssetAwareness from './AssetAwareness'
import {
  AuvOrangeIcon,
  AuvOrangeSmallIcon,
  mantaIcon,
  WavyLittoralIcon,
  WavyBasicIcon,
  WavyOceanIcon,
  WavyDummyIcon,
  WavyNosIcon,
  AvGenericIcon,
  AuvYellowIcon,
  AuvYellowSmallIcon,
  AuvRedIcon,
  AuvRedSmallIcon,
  UavIcon,
  AuvBlackIcon,
  AuvBlackSmallIcon,
  AISRedIcon,
  AISRedSmallIcon,
  GliderIcon,
  StaticSensor,
  CaravelIcon,
  RovIcon,
} from './Icons'
import RotatedMarker from './RotatedMarker'

interface PropsType {
  data: IAsset
  sliderValue: number
  currentTime: number
  isVehiclesLayerActive: boolean
  currentZoom: number
  symbolType: string
  coordsDisplayFormat: string
  setSidePanelTitle: (title: string) => void
  setSidePanelContent: (content: any) => void
  setSidePanelVisibility: (v: boolean) => void
  setEditVehicle: (_: IAsset) => void
  setAssetSelected: (_: IAsset | undefined) => void
  sidePanelVerification: () => void
}

class Vehicle extends Component<PropsType, {}> {
  public auvOrangeIcon = new AuvOrangeIcon()
  public auvOrangeSmallIcon = new AuvOrangeSmallIcon()
  public auvYellowIcon = new AuvYellowIcon()
  public auvYellowSmallIcon = new AuvYellowSmallIcon()
  public auvRedIcon = new AuvRedIcon()
  public auvRedSmallIcon = new AuvRedSmallIcon()
  public auvBlackIcon = new AuvBlackIcon()
  public auvBlackSmallIcon = new AuvBlackSmallIcon()
  public avGenericIcon = new AvGenericIcon()
  public mantaIcon = new mantaIcon()
  public wavyBasicIcon = new WavyBasicIcon()
  public wavyLittoralIcon = new WavyLittoralIcon()
  public wavyOceanIcon = new WavyOceanIcon()
  public wavyDummyIcon = new WavyDummyIcon()
  public wavyNosIcon = new WavyNosIcon()
  public uavIcon = new UavIcon()
  public aisRedIcon = new AISRedIcon()
  public aisRedSmallIcon = new AISRedSmallIcon()
  public gliderIcon = new GliderIcon()
  public staticSensor = new StaticSensor()
  public caravelIcon = new CaravelIcon()
  public rovIcon = new RovIcon()
  private positionService: PositionService = new PositionService()

  constructor(props: PropsType) {
    super(props)
    this.onMarkerClick = this.onMarkerClick.bind(this)
  }

  public shouldComponentUpdate(newProps: PropsType, newState: any) {
    return newProps.isVehiclesLayerActive
  }

  public buildSettings(settings: string[][]): any {
    const object: any = {}
    for (const pair of settings) {
      object[pair[0]] = pair[1]
    }
    return object
  }

  public getIcon(assetName: string, assetType: string | null) {
    const iconSize = 15 + this.props.currentZoom / 2

    // by name
    if (assetName.startsWith('manta')) {
      this.mantaIcon.options.iconSize = [iconSize - 8, iconSize - 8]
      return this.mantaIcon
    } else if (assetName.startsWith('WL')) {
      this.wavyLittoralIcon.options.iconSize = [iconSize - 8, iconSize - 8]
      return this.wavyLittoralIcon
    } else if (assetName.startsWith('WB')) {
      this.wavyBasicIcon.options.iconSize = [iconSize - 8, iconSize - 8]
      return this.wavyBasicIcon
    } else if (assetName.startsWith('WO')) {
      this.wavyOceanIcon.options.iconSize = [iconSize - 8, iconSize - 8]
      return this.wavyOceanIcon
    } else if (assetName.startsWith('WD')) {
      this.wavyDummyIcon.options.iconSize = [iconSize - 8, iconSize - 8]
      return this.wavyDummyIcon
    } else if (assetName.startsWith('WN')) {
      this.wavyNosIcon.options.iconSize = [iconSize - 8, iconSize - 8]
      return this.wavyNosIcon
    } else if (assetName.startsWith('lauv-xplore') || assetName.includes('-auv')) {
      this.auvYellowIcon.options.iconSize = [iconSize, iconSize]
      return this.auvYellowIcon
    } else if (
      assetName.startsWith('lauv-noptilus') ||
      assetName.startsWith('lauv-xtreme') ||
      assetName.startsWith('lauv-seacon')
    ) {
      this.auvOrangeIcon.options.iconSize = [iconSize, iconSize]
      return this.auvOrangeIcon
    } else if (assetName.startsWith('lauv-nemo')) {
      this.auvRedIcon.options.iconSize = [iconSize, iconSize]
      return this.auvRedIcon
    } else if (assetName.startsWith('caravel')) {
      this.caravelIcon.options.iconSize = [iconSize + 10, iconSize + 8]
      return this.caravelIcon
    }

    // by types
    if (assetType !== null) {
      if (assetType.startsWith('ASV')) {
        this.avGenericIcon.options.iconSize = [iconSize - 8, iconSize - 8]
        return this.avGenericIcon
      } else if (assetType.startsWith('UAV')) {
        this.uavIcon.options.iconSize = [iconSize, iconSize]
        return this.uavIcon
      } else if (assetType.startsWith('AUV')) {
        this.auvBlackIcon.options.iconSize = [iconSize, iconSize]
        return this.auvBlackIcon
      } else if (assetType.startsWith('WAVY_DRIFTER')) {
        this.wavyDummyIcon.options.iconSize = [iconSize - 8, iconSize - 8]
        return this.wavyDummyIcon
      } else if (assetType.includes('GLIDER')) {
        this.gliderIcon.options.iconSize = [iconSize + 8, iconSize + 8]
        return this.gliderIcon
      } else if (assetType.includes('ROV')) {
        this.rovIcon.options.iconSize = [iconSize - 8, iconSize - 8]
        return this.rovIcon
      } else if (assetType.startsWith('STATIC_SENSOR')) {
        this.staticSensor.options.iconSize = [iconSize, iconSize]
        return this.staticSensor
      } else if (assetType.startsWith('MOBILE_SENSOR')) {
        this.aisRedIcon.options.iconSize = [iconSize - 5, iconSize - 5]
        return this.aisRedIcon
      }
    }

    // default
    this.avGenericIcon.options.iconSize = [iconSize - 8, iconSize - 8]
    return this.avGenericIcon
  }

  public getMilitaryIcon(vehicle: IAsset) {
    const assetName: string = vehicle.name
    const assetType: string | null = vehicle.type
    const hdg: number = vehicle.lastState.heading
    const iconSize = this.props.currentZoom < 9 ? 8 : 12 + this.props.currentZoom / 2

    let symbolSIDC: string = 'SUZ---------'

    // by name
    if (assetName.startsWith('manta')) {
      symbolSIDC = 'SFZ---------'
    } else if (
      assetName.startsWith('WL') ||
      assetName.startsWith('WB') ||
      assetName.startsWith('WO') ||
      assetName.startsWith('WD') ||
      assetName.startsWith('WN') ||
      assetName.startsWith('wavy')
    ) {
      symbolSIDC = 'SFG-E-------'
    } else if (assetName.startsWith('lauv-')) {
      symbolSIDC = 'SFUPSU----*****'
    }

    // by types
    if (assetType !== null) {
      if (assetType.startsWith('ASV')) {
        symbolSIDC = 'SFSPCU----***** '
      } else if (assetType.startsWith('UAV')) {
        symbolSIDC = 'SFAPMFQ---*****'
      } else if (assetType.startsWith('AUV') || assetType.startsWith('GLIDER')) {
        symbolSIDC = 'SFUPSU----*****'
      } else if (assetType.startsWith('CCU')) {
        symbolSIDC = 'SFG-U-------'
      } else if (
        assetType.includes('STATIC_SENSOR') ||
        assetType.includes('STATIC') ||
        assetType.includes('MOBILE_SENSOR') ||
        assetType.includes('ROV')
      ) {
        symbolSIDC = 'SUZ---------'
      }
    }

    const mysymbol = new milsymbol.Symbol(symbolSIDC, {
      size: iconSize,
      type: this.props.currentZoom >= 14 ? assetName.toUpperCase() : '',
      direction: hdg !== 0 ? hdg.toString() : '',
    })

    const myIcon = L.icon({
      iconUrl: mysymbol.toDataURL(),
      iconAnchor: [mysymbol.getAnchor().x, mysymbol.getAnchor().y],
    })

    return myIcon
  }

  public buildVehicleAwareness(): JSX.Element {
    const currentVehicle = this.props.data
    const deltaHours = this.props.sliderValue
    const vehicleAwareness: IAssetAwareness = {
      name: currentVehicle.name,
      positions: currentVehicle.awareness,
    }
    return (
      <AssetAwareness
        awareness={vehicleAwareness}
        deltaHours={deltaHours}
        icon={this.getIcon(currentVehicle.name, currentVehicle.type)}
        iconAngle={0} // is used to compensate for the icon
        currentTime={this.props.currentTime}
        zoom={this.props.currentZoom}
        vehicleHeading={currentVehicle.lastState.heading}
      />
    )
  }

  public getDisplayableProperties(vehicle: IAsset) {
    let assetType = 'undefined'
    if (vehicle.type !== null) {
      assetType = vehicle.type
    }

    const mainProps = {
      heading: vehicle.lastState.heading.toFixed(2),
      'last update': DateService.timeFromNow(vehicle.lastState.timestamp),
      latitude: this.positionService.formatCoords(vehicle.lastState.latitude, 'lat', this.props.coordsDisplayFormat),
      longitude: this.positionService.formatCoords(vehicle.lastState.longitude, 'lon', this.props.coordsDisplayFormat),
      plan: vehicle.planId,
      type: assetType,
    }
    const settingsProps = this.buildSettings(vehicle.settings)
    return Object.assign({}, mainProps, settingsProps)
  }

  public onMarkerClick(vehicle: IAsset) {
    // evt.originalEvent.view.L.DomEvent.stop(evt)
    this.props.setSidePanelTitle(vehicle.name)
    this.props.setSidePanelContent(this.getDisplayableProperties(vehicle))
    this.props.setSidePanelVisibility(true)
    this.props.setEditVehicle(vehicle)
    this.props.setAssetSelected(vehicle)

    this.props.sidePanelVerification()
  }

  public buildVehicle() {
    const vehicle = this.props.data
    const systemPosition = this.positionService.getLatLng(vehicle.lastState)

    return (
      <RotatedMarker
        position={systemPosition}
        zoom={this.props.currentZoom}
        icon={this.getIcon(vehicle.name, vehicle.type)}
        rotationAngle={vehicle.lastState.heading} // is used to compensate for the icon
        rotationOrigin={'center'}
        onClick={(evt: any) => this.onMarkerClick(vehicle)}
        zIndexOffset={999}
      />
    )
  }

  public buildVehicleWithMilitarySymbols() {
    const vehicle = this.props.data
    const systemPosition = this.positionService.getLatLng(vehicle.lastState)

    return (
      <Marker
        position={systemPosition}
        icon={this.getMilitaryIcon(vehicle)}
        onClick={(evt: any) => this.onMarkerClick(vehicle)}
      />
    )
  }

  public render() {
    // military symbols
    if (this.props.symbolType === 'military') {
      return <>{this.buildVehicleWithMilitarySymbols()}</>
    } else {
      // normal symbols
      let awarenessJSX: JSX.Element | null = null
      awarenessJSX = this.buildVehicleAwareness()

      return (
        <>
          {this.buildVehicle()}
          {awarenessJSX}
        </>
      )
    }
  }
}

const actionCreators = {
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  setEditVehicle,
  sidePanelVerification,
}

function mapStateToProps(state: IRipplesState) {
  return {
    sliderValue: state.sliderValue,
  }
}

export default connect(mapStateToProps, actionCreators)(Vehicle)
