import L from 'leaflet'
import milsymbol from 'milsymbol'
import React, { Component } from 'react'
import { Marker } from 'react-leaflet'
import { connect } from 'react-redux'
import IAsset from '../../../model/IAsset'
import {
  setEditVehicle,
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  sidePanelVerification,
} from '../../../redux/ripples.actions'
import DateService from '../../../services/DateUtils'
import PositionService from '../../../services/PositionUtils'

interface PropsType {
  data: IAsset
  icon: L.Icon
  currentZoom: number
  symbolType: string
  coordsDisplayFormat: string
  setSidePanelTitle: (title: string) => void
  setSidePanelContent: (content: any) => void
  setSidePanelVisibility: (v: boolean) => void
  setEditVehicle: (v: IAsset | undefined) => void
  setAssetSelected: (v: IAsset | undefined) => void
  sidePanelVerification: () => void
}

/**
 * Simple Asset represents a system that does not execute plans.
 * Ex: Spot, CCU
 */
class SimpleAsset extends Component<PropsType, {}> {
  private positionService: PositionService = new PositionService()
  constructor(props: PropsType) {
    super(props)
    this.onMarkerClick = this.onMarkerClick.bind(this)
  }

  public buildSimpleAsset(systemPosition: L.LatLng) {
    const iconSize = 13 + this.props.currentZoom / 2

    if (this.props.data.name.includes('ccu')) {
      this.props.icon.options.iconSize = [iconSize - 8, iconSize - 8]
    } else if (this.props.data.name.includes('spot')) {
      this.props.icon.options.iconSize = [iconSize - 3, iconSize - 3]
    } else {
      this.props.icon.options.iconSize = [iconSize, iconSize]
    }

    return <Marker position={systemPosition} icon={this.props.icon} onClick={this.onMarkerClick} />
  }

  public buildSimpleAssetWithMilitarySymbols(asset: IAsset, systemPosition: L.LatLng) {
    const iconSize = this.props.currentZoom < 9 ? 8 : 12 + this.props.currentZoom / 2

    let symbolSIDC: string = 'SFZ---------'

    if (this.props.data.name.includes('ccu')) {
      symbolSIDC = 'SFG-U-------'
    }

    const mysymbol = new milsymbol.Symbol(symbolSIDC, {
      size: iconSize,
      type: this.props.currentZoom >= 14 ? asset.name.toUpperCase() : '',
    })
    const myIcon = L.icon({
      iconUrl: mysymbol.toDataURL(),
      iconAnchor: [mysymbol.getAnchor().x, mysymbol.getAnchor().y],
    })
    return <Marker position={systemPosition} icon={myIcon} onClick={this.onMarkerClick} />
  }

  public render() {
    const asset = this.props.data
    const systemPositon = this.positionService.getLatLng(asset.lastState)

    // military symbols
    if (this.props.symbolType === 'military') {
      return <>{this.buildSimpleAssetWithMilitarySymbols(asset, systemPositon)}</>
    } else {
      // normal symbols
      return <>{this.buildSimpleAsset(systemPositon)}</>
    }
  }

  private onMarkerClick(evt: any) {
    const asset = this.props.data
    evt.originalEvent.view.L.DomEvent.stopPropagation(evt)

    this.props.setSidePanelTitle(this.props.data.name)
    this.props.setSidePanelContent({
      Date: DateService.timestampSecToReadableDate(asset.lastState.timestamp),
      Lat: this.positionService.formatCoords(asset.lastState.latitude, 'lat', this.props.coordsDisplayFormat),
      Lng: this.positionService.formatCoords(asset.lastState.longitude, 'lon', this.props.coordsDisplayFormat),
    })
    this.props.setSidePanelVisibility(true)
    this.props.setEditVehicle(undefined)

    this.props.setAssetSelected(asset)

    this.props.sidePanelVerification()
  }
}

const actionCreators = {
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  setEditVehicle,
  sidePanelVerification,
}

export default connect(null, actionCreators)(SimpleAsset)
